import {
  Box,
  Button,
  Card,
  Loader,
  MarketingPageLayout,
  MarketingPageLayoutContent,
  Page,
  WixDesignSystemProvider,
  Image,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import HeroImage from "./hero.png";
import * as Icons from "@wix/wix-ui-icons-common";
import { useTranslation } from "react-i18next";
import i18n from './i18n';
import { ReactTagManager } from "react-gtm-ts"; 

const tagManagerArgs = {
  code: "GTM-WZQPMC7",  
};

function App() {
  ReactTagManager.init(tagManagerArgs); 
  const {t} = useTranslation();
  const token = new URLSearchParams(window.location.search).get("token");

  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=5c047089-b4af-4395-bd58-139524a3da1d&redirectUrl=https://certifiedcode.wixsite.com/numbers/_functions/@certifiedcode/base-backend/auth`;
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Page height="100vh">
          <Page.Content>
            <Box height={"90vh"} direction="vertical" verticalAlign="middle">
              <Loader text={t('loading')} />
            </Box>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    );
  }

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Card>
        <MarketingPageLayout
          removeImageHorizontalPadding
          removeImageVerticalPadding
          content={
            <Box height="840px" verticalAlign="middle">
              <MarketingPageLayoutContent
                title={t('number-title')}
                content={t('number-content')}
                actions={
                  <Box direction="horizontal" gap="SP1">
                    <Button
                      as="a"
                      href="https://www.youtube.com/watch?v=aQtl14IivMI"
                      target="_blank"
                      prefixIcon={<Icons.PlayFilled />}
                    >
                      {t('number-tutorial-button')}
                    </Button>
                    {/* <Button onClick={() => { window.Intercom("showArticle", 8100023) }}>See how</Button> */}
                    <Button
                      prefixIcon={<Icons.Reviews />}
                      skin="inverted"
                      as="a"
                      href="https://bridget.reviews.certifiedcode.us/5c047089-b4af-4395-bd58-139524a3da1d"
                      target="_blank"
                    >
                      {t('number-review-button')}
                    </Button>
                  </Box>
                }
              />
            </Box>
          }
          image={<Image borderRadius={0} src={HeroImage} />}
        />
      </Card>
    </WixDesignSystemProvider>
  );
}

export default App;
